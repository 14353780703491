<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form @submit="handleSubmit" :form="form">
      <a-form-item label="用户" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select :showSearch="true" label-in-value placeholder="用户ID/用户名/昵称/电话/邮箱" style="width: 100%"
          :filter-option="false" :not-found-content="fetchingUser ? undefined : null" @search="fetchUser"
          @change="handleUserChange" v-decorator="[
            'user_label',
            {
              rules: [{ required: true, message: '用户不能为空' }],
              initialValue: user_label
            },
          ]">
          <a-spin v-if="fetchingUser" slot="notFoundContent" size="small" />
          <a-select-option v-for="d in userSelectData" :key="d.value">
            {{ d.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="学员" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select placeholder="请选择" v-decorator="[
          'student_id',
          {
            rules: [{ required: true, message: '学员不能为空' }],
            initialValue: student_id
          },
        ]">
          <a-select-option value="">请选择</a-select-option>
          <a-select-option v-for="d in studentData" :key="d.id">
            {{ d.nick_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="教员" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select :showSearch="true" label-in-value placeholder="教员ID/用户名/昵称/电话/邮箱" style="width: 100%"
          :filter-option="false" :not-found-content="fetchingTeacher ? undefined : null" @search="fetchTeacher"
          @change="handleTeacherChange" v-decorator="[
            'teacher_label',
            {
              rules: [{ required: true, message: '用户不能为空' }],
              initialValue: teacher_label
            },
          ]">
          <a-spin v-if="fetchingTeacher" slot="notFoundContent" size="small" />
          <a-select-option v-for="d in teacherSelectData" :key="d.value">
            {{ d.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="服务包" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select placeholder="请选择" @change="handlePackChange" v-decorator="[
          'pack_id',
          {
            rules: [{ required: true, message: '服务包不能为空' }],
            initialValue: pack_id
          },
        ]">
          <a-select-option value="">请选择</a-select-option>
          <a-select-option v-for="d in packData" :key="d.id">
            {{ d.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="服务包描述" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <div>{{ packDesc }}</div>
      </a-form-item>
      <a-form-item label="周期范围(周)" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-col :span="16">
          <a-slider style="marginLeft: 16px" v-decorator="[
            'cycle',
            {
              rules: [{ required: true, message: '需要选择服务周期' }],
              initialValue: cycle
            }
          ]" @change="cycleChange" :min="min_cycle" :max="max_cycle" />
        </a-col>
        <a-col :span="4">
          <a-input-number v-model="cycle" :min="min_cycle" :max="max_cycle" style="marginLeft: 16px" />
        </a-col>
      </a-form-item>
      <a-form-item label="授课日" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox-group @change="weekDayChange" v-decorator="[
          'week_day',
          {
            rules: [{ required: true, message: '需要设置授课日' }],
            initialValue: week_day
          }
        ]" style="width: 100%;">
          <a-row>
            <a-col :span="3">
              <a-checkbox :disabled="saturday" value="saturday">
                周六
              </a-checkbox>
            </a-col>
            <a-col :span="3">
              <a-checkbox :disabled="sunday" value="sunday">
                周日
              </a-checkbox>
            </a-col>
            <a-col :span="3">
              <a-checkbox :disabled="monday" value="monday">
                周一
              </a-checkbox>
            </a-col>
            <a-col :span="3">
              <a-checkbox :disabled="tuesday" value="tuesday">
                周二
              </a-checkbox>
            </a-col>
            <a-col :span="3">
              <a-checkbox :disabled="wednesday" value="wednesday">
                周三
              </a-checkbox>
            </a-col>
            <a-col :span="3">
              <a-checkbox :disabled="thursday" value="thursday">
                周四
              </a-checkbox>
            </a-col>
            <a-col :span="3">
              <a-checkbox :disabled="friday" value="friday">
                周五
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-item>
      <a-form-item :label="'时长' + week_name" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-col v-if="currentCheck" :span="16">
          <a-slider style="marginLeft: 16px" v-model="duration" @change="durationChange" :min="min_duration"
            :max="max_duration" />
        </a-col>
        <a-col v-if="currentCheck" :span="4">
          <a-input-number v-model="duration" :min="min_duration" :max="max_duration" style="marginLeft: 16px" />
        </a-col>
      </a-form-item>
      <a-form-item label="描述" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea :auto-size="{ minRows: 3, maxRows: 5 }" placeholder="请输入描述信息" v-decorator="[
          'desc'
        ]" />
      </a-form-item>
      <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea :auto-size="{ minRows: 3, maxRows: 5 }" placeholder="请输入备注信息" v-decorator="[
          'notes'
        ]" />
      </a-form-item>
      <a-form-item label="金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <div>单价：{{ price }}元/小时</div>
        <div>周期：{{ cycle }}周</div>
        <div>每周时长：{{ weekHourStr }}</div>
        <div>小计：{{ weekHours * price * cycle }}元</div>
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { orderCreate } from '@/api/order'
import { userSearch } from '@/api/user'
import { teacherSearch } from '@/api/teacher'
import { packUsed } from '@/api/service_pack'
import debounce from 'lodash/debounce'
import { Slider as ASlider } from 'ant-design-vue'

export default {
  name: 'AddOrder',
  components: {
    ASlider
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      description: '添加订单',
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      queryData: {},
      checkPass: true,

      price: 0,
      weekHourStr: '',
      weekHours: 0,
      total: 0,
      // form
      form: this.$form.createForm(this),
      userData: [],
      userSelectData: [],
      teacherSelectData: [],
      teacherData: [],
      userValue: [],
      teacherValue: [],
      fetchingUser: false,
      fetchingTeacher: false,
      submitLoading: false,
      packData: [],
      packDesc: '',
      studentData: [],
      user_label: {},
      student_id: '',
      teacher_label: '',
      pack_id: '',
      allow_week_day: {},
      allow_user_hour: {},
      packDataItem: {},
      // 授课日是否禁用数据
      saturday: true,
      sunday: true,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      // 逻辑数据
      min_cycle: 1,
      max_cycle: 10,
      cycle: 2,
      week_day: [],
      week_name: '',
      // 授课时长显示
      duration: 2,
      min_duration: 2,
      max_duration: 6,
      currentCheck: '',
      teachingData: {},
      packUsed: {}
    }
  },
  created() {
  },
  watch: {
    user_label(val) {
      const _this = this
      // 用户的修改，修改用户学员的数据
      this.userData.forEach(function (value, index) {
        if (value.id === val) {
          _this.studentData = value.student
        }
      })
    },
    teacher_label(val) {
      const _this = this
      // 教员的修改，修改教员服务包的数据
      this.teacherData.forEach(function (value, index) {
        if (value.id === val) {
          _this.packData = value.service_pack
        }
      })
    },
    packData(val) {
    },
    pack_id(val) {
      const _this = this
      this.packData.forEach(function (value, index) {
        if (value.id === val) {
          _this.packDataItem = value
        }
      })
    },
    packDataItem(val) {
      // 服务包支持的最小最大周期
      this.min_cycle = val.min_cycle
      this.max_cycle = val.max_cycle
      this.price = val.price
      this.packDesc = val.desc
      // 服务包支持的授课日，在后台须处理该授课日时间是否被安排完
    },
    allow_week_day(val) {
      if (val.indexOf('saturday') > -1) {
        this.saturday = false
      }

      if (val.indexOf('sunday') > -1) {
        this.sunday = false
      }

      if (val.indexOf('monday') > -1) {
        this.monday = false
      }

      if (val.indexOf('tuesday') > -1) {
        this.tuesday = false
      }

      if (val.indexOf('wednesday') > -1) {
        this.wednesday = false
      }

      if (val.indexOf('thursday') > -1) {
        this.thursday = false
      }

      if (val.indexOf('friday') > -1) {
        this.friday = false
      }
    },
    allow_user_hour(val) {
    },
    cycle(val) {
    },
    week_day(val, oldVal) {
      var currentCheck = this.getArrDifference(val, oldVal)[0]
      // 勾选新元素
      if (val.length > oldVal.length) {
        this.currentCheck = currentCheck
        this.$set(this.teachingData, currentCheck, 2)
      } else if (val.length < oldVal.length) {
        this.currentCheck = ''
        this.$delete(this.teachingData, currentCheck)
      }
    },
    currentCheck(val) {
      const weekName = {
        'saturday': '周六',
        'sunday': '周日',
        'monday': '周一',
        'tuesday': '周二',
        'wednesday': '周三',
        'thursday': '周四',
        'friday': '周五'
      }
      if (val) {
        // 设置拉条的基础数据
        this.week_name = '(' + weekName[val] + ')'
        this.min_duration = 2
        this.max_duration = this.allow_user_hour[val]
      } else {
        this.week_name = ''
      }
    },
    duration(val) {
      // 处理授课日拉条数据
      if (this.currentCheck) {
        var currentCheck = this.currentCheck
        this.teachingData[currentCheck] = val
        console.log(this.teachingData)
      }
    },
    teachingData: {
      handler(val, oldval) {
        var weekHourStr = ''
        var weekHours = 0
        if (val) {
          for (var prop in val) {
            weekHours += val[prop]
            if (weekHourStr === '') {
              weekHourStr = val[prop]
            } else {
              weekHourStr = weekHourStr + ' + ' + val[prop]
            }
          }
        }
        this.weekHours = weekHours
        this.weekHourStr = weekHourStr
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    cycleChange(e) {
      this.cycle = e
    },
    handlePackChange(e) {
      this.pack_id = e
      if (e) {
        new Promise((resolve, reject) => {
          packUsed({ pack_id: e }).then(response => {
            if (response.status === 'success') {
              resolve(response.data)
            } else {
              reject(response)
            }
          })
        }).then(data => {
          this.allow_week_day = data.allow_week_day
          this.allow_user_hour = data.allow_user_hour
        }).catch(error => {
          this.$notification.error({
            message: '错误',
            description: error.message
          })
        })
      }
    },
    durationChange(e) {
      this.duration = e
    },
    weekDayChange(e) {
      this.week_day = e
    },
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v)
      })
    },
    fetchUser(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.data = []
      new Promise((resolve, reject) => {
        this.fetchingUser = true
        userSearch({ keywords: value }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }
        if (res.data) {
          const userSelectData = res.data.map(user => ({
            text: `${user.username} - ${user.nick_name}`,
            value: user.id
          }))
          this.userSelectData = userSelectData
        } else {
          this.userSelectData = []
        }
        this.userData = res.data
        this.fetchingUser = false
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
      })
    },
    fetchTeacher(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.data = []
      new Promise((resolve, reject) => {
        this.fetchingTeacher = true
        teacherSearch({ keywords: value }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }
        if (res.data) {
          const teacherSelectData = res.data.map(teacher => ({
            text: `${teacher.username} - ${teacher.nick_name}`,
            value: teacher.id
          }))
          this.teacherSelectData = teacherSelectData
        } else {
          this.teacherSelectData = []
        }
        this.teacherData = res.data
        this.fetchingTeacher = false
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
      })
    },
    handleUserChange(value) {
      this.user_label = value.key
    },
    handleTeacherChange(value) {
      this.teacher_label = value.key
    },
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          // 新增
          values.teaching_data = this.teachingData
          console.log('submit')
          console.log(this.teachingData)
          console.log(values)
          new Promise((resolve, reject) => {
            this.submitLoading = true
            values.user_id = values.user_label.key
            values.teacher_id = values.teacher_label.key
            orderCreate(values).then(response => {
              if (response.status === 'success') {
                resolve(response)
              } else {
                reject(response)
              }
            })
          }).then(res => {
            this.$notification.success({
              message: res.status,
              description: res.message
            })
            // 跳转到订单详情页面
            this.$router.push({ 'name': 'OrderEdit', query: { id: res.data.order_id } })
          }).catch(error => {
            this.$notification.error({
              message: '错误',
              description: error.message
            })
          }).finally(() => {
            this.submitLoading = false
          })
        }
      })
    },
    routerback: function () {
      this.$router.back(-1)
    }
  }
}
</script>
